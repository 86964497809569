<template>
  <section>
    <div class="main-left col-md-9">
      <ul class="sep-datalist-history1">
        <li class="clearfix">
          <div class="text">
            <div class="date">
              <div class="y">2001</div>
            </div>
            <img
              src="https://www.newdosepump.com/themes/simple/img/clock2.png"
              alt="2001"
              class="clock"
            />
            Ark Electric and Mechanical Company Limited was founded.
          </div>
        </li>
        <li class="clearfix">
          <div class="text">
            <div class="date">
              <div class="y">2004</div>
            </div>
            <img
              src="https://www.newdosepump.com/themes/simple/img/clock2.png"
              alt="2004"
              class="clock"
            />
            NEWDOSE brand was founded, the first electromagnetic dosing pump
            formally came into China market.
          </div>
        </li>
        <li class="clearfix">
          <div class="text">
            <div class="date">
              <div class="y">2005</div>
            </div>
            <img
              src="https://www.newdosepump.com/themes/simple/img/clock2.png"
              alt="2005"
              class="clock"
            />
            R&amp;D of solenoid pumps DP (pulse signal) and DM (4-20mA signal)
            series succeeded.
          </div>
        </li>
        <li class="clearfix">
          <div class="text">
            <div class="date">
              <div class="y">2006</div>
            </div>
            <img
              src="https://www.newdosepump.com/themes/simple/img/clock2.png"
              alt="2006"
              class="clock"
            />
            R&amp;D of solenoid pumps big capacity 50Lph succeeded.
          </div>
        </li>
        <li class="clearfix">
          <div class="text">
            <div class="date">
              <div class="y">2015</div>
            </div>
            <img
              src="https://www.newdosepump.com/themes/simple/img/clock2.png"
              alt="2015"
              class="clock"
            />
            Whole series of electromagnetic pump went through upgraded and
            updated by continuous technological renovation.
          </div>
        </li>
        <li class="clearfix">
          <div class="text">
            <div class="date">
              <div class="y">2016</div>
            </div>
            <img
              src="https://www.newdosepump.com/themes/simple/img/clock2.png"
              alt="2016"
              class="clock"
            />
            R&amp;D of motor driven diaphragm metering pump succeeded and came
            out.
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
export default {
  title: "Our History",
};
</script>